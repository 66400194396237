import { combineReducers } from '@reduxjs/toolkit';
import { reducer as agencyReducer } from './slices/agency';
import { reducer as agencyUserReducer } from './slices/agencyUser';
import { reducer as bankAccountReducer } from './slices/bankAccount';
import { reducer as bankEntryReducer } from './slices/bankEntry';
import { reducer as branchReducer } from './slices/branch';
import { reducer as branchCashboxReducer } from './slices/branchCashbox';
import { reducer as cityReducer } from './slices/city';
import { reducer as classReducer } from './slices/class';
import { reducer as colorReducer } from './slices/color';
import { reducer as confirmAccountReducer } from './slices/confirmAccount';
import { reducer as countryReducer } from './slices/country';
import { reducer as customerReducer } from './slices/customer';
import { reducer as enumReducer } from './slices/enum';
import { reducer as expensesReducer } from './slices/expense';
import { reducer as extraReducer } from './slices/extra';
import { reducer as invoiceReducer } from './slices/invoice';
import { reducer as locationReducer } from './slices/location';
import { reducer as modelReducer } from './slices/model';
import { reducer as nationalityReducer } from './slices/nationality';
import { reducer as permissionsReducer } from './slices/permissions';
import { reducer as pricingGroupReducer } from './slices/pricingGroup';
import { reducer as pricingGroupSeasonReducer } from './slices/pricingGroupSeason';
import { reducer as rentableReducer } from './slices/rentable';
import { reducer as rentableExtraTypeReducer } from './slices/rentableExtraType';
import { reducer as rentableTypeReducer } from './slices/rentableType';
import { reducer as reservationReducer } from './slices/reservation';
import { reducer as seasonReducer } from './slices/seasons';
import { reducer as stateReducer } from './slices/state';
import { reducer as statisticReducer } from './slices/statistics';
import { reducer as timeZoneReducer } from './slices/timeZone';
import { reducer as reservationChecksReducer } from './slices/reservationChecks';
import { reducer as configReducer } from './slices/config';
// ----------------------------------------------------------------------
const combinedReducer = combineReducers({
    branch: branchReducer,
    confirmAccount: confirmAccountReducer,
    state: stateReducer,
    city: cityReducer,
    location: locationReducer,
    class: classReducer,
    rentableType: rentableTypeReducer,
    rentableExtraType: rentableExtraTypeReducer,
    rentable: rentableReducer,
    model: modelReducer,
    color: colorReducer,
    enum: enumReducer,
    pricingGroup: pricingGroupReducer,
    pricingGroupSeason: pricingGroupSeasonReducer,
    season: seasonReducer,
    reservation: reservationReducer,
    customer: customerReducer,
    extra: extraReducer,
    expenses: expensesReducer,
    statistic: statisticReducer,
    country: countryReducer,
    agency: agencyReducer,
    agencyUser: agencyUserReducer,
    timeZone: timeZoneReducer,
    nationality: nationalityReducer,
    permissions: permissionsReducer,
    invoice: invoiceReducer,
    bankAccount: bankAccountReducer,
    bankEntry: bankEntryReducer,
    branchCashbox: branchCashboxReducer,
    reservationChecks: reservationChecksReducer,
    config: configReducer,
});
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
export default rootReducer;
